import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import * as page from "../../pages";
import { NAV } from "./nav-links";
import { MainLayout } from "@/widgets/layout/main-layout/MainLayout";

export const routerApp = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={"/"} element={<MainLayout />}>
        <Route path={NAV.HOME} element={<page.MainLazyPage />} />
        <Route path={NAV.ORDERS} element={<page.OrdersLazyPage />} />
        <Route path={NAV.ORDER} element={<page.OrderDetailsLazyPage />} />
        <Route path={NAV.PROFILE} element={<page.ProfileLazyPage />} />
        <Route path={NAV.INSTUCTION} element={<page.InstructionLazyPage />} />
        <Route path={NAV.UNAUTHORIZED} element={<page.UnathorizedLazyPage />} />
      </Route>

      <Route path={NAV.LEAD} element={<page.LeadLazyPage />} />
      <Route path={NAV.NOTFOUND} element={<page.NotFoundLazyPage />} />
      <Route path={NAV.LOGIN} element={<page.LoginLazyPage />} />
      <Route path={NAV.LOGINID} element={<page.LoginLazyPage />} />
    </>
  )
);
